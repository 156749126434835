import ArtistSongRows from 'views/Artist/ArtistSongRows';
import Head from './Head';
import ListenInApp from 'components/ListenInApp';
import PageBody, { ViewName } from 'views/PageBody';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import SimilarArtistAside from '../SimilarArtistAside';
import { getArtistUrl } from 'utils/url';
import { PlaybackTypeValue, STATION_TYPE } from 'constants/stationTypes';
import { Track } from 'state/Tracks/types';

type Props = {
  artistId: number;
  artistName: string;
  tracks: Array<Track>;
};

function ArtistSongs({ artistId, artistName, tracks }: Props) {
  const similar = (
    <SimilarArtistAside
      key="similar"
      playedFrom={PLAYED_FROM.PROF_SONG_SIMILAR}
    />
  );

  return artistId ? (
    <>
      <Head />
      <ListenInApp seedId={artistId} seedType="artist" />
      <PageBody
        backLink={getArtistUrl(artistId, artistName) as string}
        dataTest={ViewName.ArtistSongs}
        more={similar}
        title={artistName}
      >
        <SectionHeader title="Songs" />
        <ArtistSongRows
          artistId={artistId}
          artistName={artistName}
          playedFrom={PLAYED_FROM.PROF_ARTIST_SONGS}
          stationType={STATION_TYPE.TRACK as PlaybackTypeValue}
          tracks={tracks}
        />
      </PageBody>
    </>
  ) : null;
}

export default ArtistSongs;
